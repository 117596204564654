<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper class="tw-flex tw-items-center tw-justify-end">
                <div class="tw-flex tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('loanRepayment.searchDeleted')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                        enter-button
                        @on-search="onSearch"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :show-summary="true"
                :summary-method="handleSummary"
                stripe
                :loading="loading"
                max-height="700"
            >
                <template slot-scope="{ row }" slot="loan_number">
                    {{
                        row.loan_disbursement
                            ? row.loan_disbursement.loan_number
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="customer_name">
                    {{ row.customer ? row.customer.customer_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="actual_payment_date">
                    <span class="badge bg-info rounded-pill">{{
                        row.actual_payment_date
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="principal_paid">
                    {{
                        formatCurrencyWithCode(
                            row.principal_paid,
                            row.loan_disbursement.currency
                        )
                    }}
                </template>
                <template slot-scope="{ row }" slot="interest_paid">
                    {{
                        formatCurrencyWithCode(
                            row.interest_paid,
                            row.loan_disbursement.currency
                        )
                    }}
                </template>
                <template slot-scope="{ row }" slot="monthly_fee_paid">
                    {{ formatCurrencyWithCode(row.monthly_fee_paid, row.loan_disbursement.currency) }}
                </template>
                <template slot-scope="{ row }" slot="rescue_fee_paid">
                    {{ formatCurrencyWithCode(row.rescue_fee_paid, row.loan_disbursement.currency) }}
                </template>
                <template slot-scope="{ row }" slot="actual_payment_amount">
                    <span class="badge bg-primary rounded-pill">{{
                        formatCurrencyWithCode(
                            row.actual_payment_amount,
                            row.loan_disbursement.currency
                        )
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="overdue_penalty_paid">
                    {{
                        formatCurrencyWithCode(
                            row.overdue_penalty_paid,
                            row.loan_disbursement.currency
                        )
                    }}
                </template>
                <template slot-scope="{ row }" slot="reschedule_penalty_paid"
                    >{{
                        formatCurrencyWithCode(
                            row.reschedule_penalty_paid,
                            row.loan_disbursement.currency
                        )
                    }}
                </template>
                <template slot-scope="{ row }" slot="pay_off_penalty_paid"
                    >{{
                        formatCurrencyWithCode(
                            row.pay_off_penalty_paid,
                            row.loan_disbursement.currency
                        )
                    }}
                </template>
                <template slot-scope="{ row }" slot="outstanding_balance">
                    {{
                        formatCurrencyWithCode(
                            row.outstanding_balance,
                            row.loan_disbursement.currency
                        )
                    }}
                </template>
                <template slot-scope="{ row }" slot="amount_excess">
                    {{
                        formatCurrencyWithCode(
                            row.amount_excess,
                            row.loan_disbursement.currency
                        )
                    }}
                </template>
                <template slot-scope="{ row }" slot="co_name">
                    {{
                        row.loan_disbursement.co
                            ? row.loan_disbursement.co.employee_name_en
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="deleted_by">
                    {{
                        row.deleted_by.employee
                            ? row.deleted_by.employee.employee_name_en
                            : row.deleted_by.user_name
                    }}
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('loanRepayment.actualpaymentDate')
                }}</label>
                <DatePicker
                    placement="bottom-end"
                    style="width: 100%"
                    :transfer="true"
                    format="dd-MM-yyyy"
                    @on-change="onChangeActualPaymentDate"
                    v-model="actual_payment_date"
                ></DatePicker>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('summaryOfActiveAccount.currency')
                }}</label
                ><br />
                <RadioGroup v-model="currency_id">
                    <Radio
                        class="mt-1"
                        :label="c.currency_id"
                        border
                        v-for="(c, index) in currencies"
                        :key="index"
                        size="small"
                    >
                        {{ c.currency_code }}
                    </Radio>
                </RadioGroup>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { debounce } from 'lodash'
import moment from "moment";

export default {
    name: 'loanRepaymentDeleted',
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            visible: false,
            currency_id: null,
            actual_payment_date: moment().format("DD-MM-YYYY")
        }
    },
    computed: {
        ...mapState('creditOperation/loanRepaymentDeleted', [
            'resources',
            'pagination'
        ]),
        ...mapState('report/resource', ['currencies']),
        ...mapGetters(['formatCurrencyWithCode']),
        search: {
            get () {
                return this.$store.state.creditOperation.loanRepaymentDeleted
                    .search
            },
            set (newValue) {
                this.$store.commit(
                    'creditOperation/loanRepaymentDeleted/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'creditOperation/loanRepaymentDeleted/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    title: this.$t('loanRepayment.loanNumber'),
                    slot: 'loan_number',
                    sortable: true,
                    width: 150
                },
                {
                    title: this.$t('loanRepayment.customerName'),
                    key: 'customer_name',
                    slot: 'customer_name',
                    minWidth: 190,
                    sortable: true
                },
                {
                    title: this.$t('loanRepayment.cyclePaymentDate'),
                    key: 'cycle_payment_date',
                    sortable: true,
                    align: 'center',
                    width: 160
                },
                {
                    title: this.$t('loanRepayment.actualpaymentDate'),
                    key: 'actual_payment_date',
                    slot: 'actual_payment_date',
                    align: 'center',
                    sortable: true,
                    width: 180
                },
                {
                    title: this.$t('loanRepayment.principal'),
                    key: 'principal_paid',
                    slot: 'principal_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.interest'),
                    key: 'interest_paid',
                    slot: 'interest_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.monthlyFee'),
                    key: 'monthly_fee_paid',
                    slot: 'monthly_fee_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.rescueFund'),
                    key: 'rescue_fee_paid',
                    slot: 'rescue_fee_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.actualPaymentAmount'),
                    key: 'actual_payment_amount',
                    slot: 'actual_payment_amount',
                    minWidth: 150,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.overduePenalty'),
                    key: 'overdue_penalty_paid',
                    slot: 'overdue_penalty_paid',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.reschedulePenalty'),
                    key: 'reschedule_penalty_paid',
                    slot: 'reschedule_penalty_paid',
                    minWidth: 160,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.payOffPenalty'),
                    key: 'pay_off_penalty_paid',
                    slot: 'pay_off_penalty_paid',
                    minWidth: 130,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.outstandingBalance'),
                    key: 'outstanding_balance',
                    slot: 'outstanding_balance',
                    minWidth: 150,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.amountExcess'),
                    key: 'amount_excess',
                    slot: 'amount_excess',
                    minWidth: 150,
                    align: 'right'
                },
                {
                    title: this.$t('loanRepayment.co'),
                    key: 'co_name',
                    slot: 'co_name',
                    minWidth: 190,
                    sortable: true
                },
                {
                    title: this.$t('loanRepayment.deletedBy'),
                    key: 'deleted_by',
                    slot: 'deleted_by',
                    minWidth: 150,
                    fixed: 'right'
                },
                {
                    title: this.$t('loanRepayment.deletedDate'),
                    key: 'deleted_date',
                    minWidth: 150,
                    align: 'center',
                    fixed: 'right'
                },
                {
                    title: this.$t('loanRepayment.reason'),
                    key: 'deleted_reason',
                    minWidth: 200,
                    fixed: 'right'
                }
            ]
        }
    },
    created () {
        this.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('creditOperation/loanRepaymentDeleted/fetch', {
                    ...attributes,
                    currency_id: this.currency_id,
                    actual_payment_date: this.actual_payment_date,
                    search: this.search
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        handleSummary ({ columns, data }) {
            const sums = {}
            columns.forEach((column, index) => {
                const key = column.key
                if (index === 0) {
                    sums[key] = {
                        key,
                        value: this.$t('loanRepayment.total')
                    }
                    return
                }
                if (
                    index === 10 ||
                    index === 12 ||
                    index === 13 ||
                    index === 14 ||
                    index === 15
                ) {
                    sums[key] = {
                        key,
                        value: ''
                    }
                    return
                }
                const currency = data.map(item => item.loan_disbursement.currency)
                const values = data.map(item => Number(item[key]))
                if (!values.every(value => isNaN(value))) {
                    const v = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    sums[key] = {
                        key,
                        value: this.formatCurrencyWithCode(v, currency[0])
                    }
                } else {
                    sums[key] = {
                        key,
                        value: 'N/A'
                    }
                }
            })

            return sums
        },
        onChangeActualPaymentDate (date) {
            this.actual_payment_date = date
            this.fetchData()
        },
        onSearch(){
            if(!this.search) return
            this.fetchData()
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'REPAYMENT DELETED',
                desc: not.text
            })
        }
    },
    watch: {
        currency_id: debounce(function () {
                this.fetchData()
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/loanRepaymentDeleted/RESET_STATE')
        next()
    }
}
</script>
