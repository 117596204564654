<template>
    <div>
        <ts-page-title
            :title="$t('loanRepayment.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanRepayment.pageTitle'),
                    active: true
                }
            ]"
        />
        <Tabs @on-click="changeTab" v-model="tabValue">
            <TabPane label="Repayment Lists" icon="ios-cash" name="repayment_list">
                <ts-panel>
                    <ts-panel-wrapper
                        class="tw-flex tw-items-center tw-justify-between"
                    >
                        <div class="tw-flex">
                            <Button
                                type="info"
                                ghost
                                @click="
                                    $router.push({
                                        name: 'loan-repayment-create'
                                    })
                                "
                            >
                                {{ $t("addNew") }}
                            </Button>
                            <Button
                                type="primary"
                                ghost
                                @click="
                                    $router.push({
                                        name: 'loan-repayment-group-create'
                                    })
                                "
                                class="tw-ml-2"
                            >
                                {{ $t("loanRepayment.repaymentByGroup") }}
                            </Button>
                        </div>
                        <div class="tw-flex tw-justify-end tw-space-x-2">
                            <Input
                                search
                                v-model="search"
                                :placeholder="$t('loanRepayment.search')"
                                :style="width"
                                enter-button
                                @on-search="onSearch"
                            />
                            <ts-button
                                v-tooltip="$t('filter')"
                                @click.prevent="visible = true"
                            >
                                <i class="fa fa-filter"></i>
                            </ts-button>
                        </div>
                    </ts-panel-wrapper>
                    <Table
                        :columns="columns"
                        :data="resources"
                        size="small"
                        :loading="loading"
                        max-height="700"
                        stripe
                    >
                        <template slot-scope="{ row }" slot="branch">
                            {{ row.branch ? row.branch.branch_name_en : "" }}
                        </template>
                        <template slot-scope="{ row }" slot="photo">
                            <img
                                class="tw-rounded tw-h-8"
                                v-lazy="{
                                    src: photo(row),
                                    error:
                                        row.sex == 'Male'
                                            ? require('@/assets/staff-default-man.png')
                                            : require('@/assets/staff-default-women.png'),
                                    loading: require('@/assets/Rolling-1s-200px.svg'),
                                    preLoad: 1.3,
                                    attempt: 1
                                }"
                            />
                        </template>
                    </Table>
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-p-4"
                    >
                        <div>
                            <ts-button
                                color="success"
                                outline
                                class="tw-mr-2 btn-sm"
                                @click="onConfirmRepayment"
                                :disabled="resources.length <= 0"
                            >
                                <i
                                    class="fas fa-circle-notch fa-spin"
                                    v-if="confirming"
                                ></i>
                                <i class="far fa-check-circle" v-else></i>
                                {{ $t("loanRepayment.confirmRepayment") }} ({{
                                    employee_id.length == 0
                                        ? $t("all")
                                        : employee_id.length
                                }}) {{ $t("loanRepayment.coName") }}</ts-button
                            >
                        </div>
                        <ts-i-pagination
                            v-model="pagination"
                            @navigate="fetchData"
                            :show-sizer="false"
                        ></ts-i-pagination>
                    </div>
                </ts-panel>
            </TabPane>
            <TabPane label="Repayment Deleted" icon="md-trash" name="repayment_deleted">
                <repayment-deleted
                    v-if="tabValue == 'repayment_deleted'"
                    ref="loan_repayment_deleted"
                ></repayment-deleted>
            </TabPane>
        </Tabs>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("branchName")
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("loanRepayment.coName")
                }}</label>
                <Select
                    v-model="employee_id"
                    multiple
                    :max-tag-count="1"
                    :filterable="true"
                    :filter-by-label="true"
                    @on-change="onChangeEmployee"
                >
                    <Option
                        v-for="item in employees"
                        :value="item.employee_id"
                        :key="item.employee_id"
                        :label="item.employee_name_en"
                    >
                        {{ item.employee_name_en }}
                    </Option>
                </Select>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("loanRepayment.isApproved")
                }}</label>
                <Select v-model="approve_status">
                    <Option
                        v-for="item in isApproved"
                        :value="item.id"
                        :key="item.id"
                        :label="item.status"
                    >
                        {{ item.status }}
                    </Option>
                </Select>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("loanRepayment.actualpaymentDate")
                }}</label>
                <DatePicker
                    placement="bottom-end"
                    style="width: 100%"
                    :transfer="true"
                    format="dd-MM-yyyy"
                    @on-change="onChangeActualPaymentDate"
                    v-model="actual_payment_date"
                ></DatePicker>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("summaryOfActiveAccount.currency")
                }}</label
                ><br />
                <RadioGroup v-model="currency_id">
                    <Radio
                        class="mt-1"
                        :label="c.currency_id"
                        border
                        v-for="(c, index) in currencies"
                        :key="index"
                        size="small"
                    >
                        {{ c.currency_code }}
                    </Radio>
                </RadioGroup>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import expandRow from "./table-expand.vue";
import moment from "moment";
import repaymentDeleted from "./components/repayment-deleted.vue";

export default {
    components: { repaymentDeleted },
    name: "loanRepaymentIndex",
    data() {
        return {
            loading: false,
            width: "width: 400px",
            confirming: false,
            visible: false,
            currency_id: null,
            branch_id: [],
            employee_id: [],
            actual_payment_date: moment().format("DD-MM-YYYY"),
            approve_status: 1,
            tabValue: 'repayment_list',
            isApproved: [
                {
                    id: 1,
                    status: "Yes"
                },
                {
                    id: 0,
                    status: "No"
                }
            ]
        };
    },
    created() {
        this.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id;
    },
    computed: {
        ...mapState("creditOperation/loanRepayment", [
            "pagination",
            "employees"
        ]),
        ...mapState("report/resource", ["currencies"]),
        search: {
            get() {
                return this.$store.state.creditOperation.loanRepayment.search;
            },
            set(newValue) {
                this.$store.commit(
                    "creditOperation/loanRepayment/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "creditOperation/loanRepayment/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    type: "expand",
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                loan_disbursements:
                                    params.row.loan_disbursements
                            }
                        });
                    },
                    width: 20
                },
                {
                    title: this.$t("loanRepayment.branch"),
                    slot: "branch",
                    width: 160
                },
                {
                    title: this.$t("loanRepayment.photo"),
                    slot: "photo",
                    width: 90
                },
                {
                    title: this.$t("loanRepayment.coNameEn"),
                    key: "employee_name_en",
                    sortable: true,
                    minWidth: 210
                },
                {
                    title: this.$t("loanRepayment.coNameKh"),
                    key: "employee_name_kh",
                    sortable: true,
                    minWidth: 210
                },
                {
                    title: this.$t("loanRepayment.sex"),
                    key: "sex",
                    width: 90,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("loanRepayment.phone"),
                    key: "phone",
                    width: 150,
                    align: "center",
                    sortable: true
                }
            ];
        },
        resources() {
            let resources = [];
            this.$store.state.creditOperation.loanRepayment.resources.forEach(
                resource => {
                    resources.push(
                        Object.assign(resource, { _expanded: true })
                    );
                }
            );
            return resources;
        }
    },
    methods: {
        ...mapActions("report/resource", ["getCurrency"]),
        ...mapActions("creditOperation/loanRepayment", ["getEmployees"]),
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.sex == "Male"
                ? require("@/assets/staff-default-man.png")
                : require("@/assets/staff-default-women.png");
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/loanRepayment/fetch", {
                    ...attributes,
                    branch_id: this.branch_id,
                    actual_payment_date: this.actual_payment_date,
                    approve_status: this.approve_status,
                    employee_id: this.employee_id
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onConfirmRepayment() {
            this.confirming = true;
            this.$store
                .dispatch("creditOperation/loanRepayment/confirmRepayment", {
                    employee_id_list: this.employee_id,
                    actual_payment_date: this.actual_payment_date
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.fetchData({
                        branch_id: this.branch_id,
                        approve_status: this.approve_status,
                        employee_id: this.employee_id,
                        currency_id: this.currency_id,
                        actual_payment_date: this.actual_payment_date
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.confirming = false;
                });
        },
        onChangeActualPaymentDate(date) {
            this.actual_payment_date = date;
            this.fetchData({
                branch_id: this.branch_id,
                approve_status: this.approve_status,
                employee_id: this.employee_id,
                currency_id: this.currency_id,
                actual_payment_date: date
            });
        },
        onChangeEmployee(value) {
            this.fetchData({
                employee_id: value,
                approve_status: this.approve_status,
                currency_id: this.currency_id,
                actual_payment_date: this.actual_payment_date
            });
        },
        onView(record) {
            this.$store.commit(
                "creditOperation/loanRepayment/SET_EDIT_DATA",
                record
            );
        },
        onEdit(record) {
            this.$store.commit(
                "creditOperation/loanRepayment/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "loan-repayment-edit",
                params: { id: record.transaction_id }
            });
        },
        changeTab(e) {
            if (e == 'repayment_list') {
                this.fetchData();
            }
        },
        onSearch(){
            if(!this.search) return
            this.fetchData({
                search: this.search,
                branch_id: this.branch_id,
                employee_id: this.employee_id,
                currency_id: this.currency_id,
                approve_status: this.approve_status,
                actual_payment_date: this.actual_payment_date
            })
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN REPAYMENT",
                desc: not.text
            });
        }
    },
    watch: {
        branch_id: debounce(function(value) {
            this.employee_id = [];
            if (
                value.length > 0 &&
                this.approve_status &&
                this.currency_id &&
                this.actual_payment_date
            ) {
                this.fetchData({
                    branch_id: value,
                    approve_status: this.approve_status,
                    currency_id: this.currency_id,
                    employee_id: this.employee_id,
                    actual_payment_date: this.actual_payment_date
                });
            }
            this.getEmployees({
                branch_id: value
            });
        }, 500),
        currency_id: debounce(function(value) {
            if (
                this.branch_id.length > 0 &&
                this.approve_status &&
                this.actual_payment_date
            ) {
                this.fetchData({
                    currency_id: value,
                    approve_status: this.approve_status,
                    employee_id: this.employee_id,
                    actual_payment_date: this.actual_payment_date
                });
            }
        }, 500),
        approve_status: debounce(function(value) {
            if (
                this.branch_id.length > 0 &&
                this.currency_id &&
                this.actual_payment_date
            ) {
                this.fetchData({
                    approve_status: value,
                    branch_id: this.branch_id,
                    employee_id: this.employee_id,
                    currency_id: this.currency_id,
                    actual_payment_date: this.actual_payment_date
                });
            }
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getCurrency();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("creditOperation/loanRepayment/RESET_STATE");
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
