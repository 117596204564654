var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-end"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('loanRepayment.searchDeleted'),"enter-button":""},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); },"on-search":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ts-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('filter')),expression:"$t('filter')"}],on:{"click":function($event){$event.preventDefault();_vm.visible = true}}},[_c('i',{staticClass:"fa fa-filter"})])],1)]),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","show-summary":true,"summary-method":_vm.handleSummary,"stripe":"","loading":_vm.loading,"max-height":"700"},scopedSlots:_vm._u([{key:"loan_number",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan_disbursement ? row.loan_disbursement.loan_number : '')+" ")]}},{key:"customer_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.customer ? row.customer.customer_name_en : '')+" ")]}},{key:"actual_payment_date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"badge bg-info rounded-pill"},[_vm._v(_vm._s(row.actual_payment_date))])]}},{key:"principal_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.principal_paid, row.loan_disbursement.currency ))+" ")]}},{key:"interest_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.interest_paid, row.loan_disbursement.currency ))+" ")]}},{key:"monthly_fee_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.monthly_fee_paid, row.loan_disbursement.currency))+" ")]}},{key:"rescue_fee_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.rescue_fee_paid, row.loan_disbursement.currency))+" ")]}},{key:"actual_payment_amount",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"badge bg-primary rounded-pill"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.actual_payment_amount, row.loan_disbursement.currency )))])]}},{key:"overdue_penalty_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.overdue_penalty_paid, row.loan_disbursement.currency ))+" ")]}},{key:"reschedule_penalty_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.reschedule_penalty_paid, row.loan_disbursement.currency ))+" ")]}},{key:"pay_off_penalty_paid",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatCurrencyWithCode( row.pay_off_penalty_paid, row.loan_disbursement.currency ))+" ")]}},{key:"outstanding_balance",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.outstanding_balance, row.loan_disbursement.currency ))+" ")]}},{key:"amount_excess",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( row.amount_excess, row.loan_disbursement.currency ))+" ")]}},{key:"co_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan_disbursement.co ? row.loan_disbursement.co.employee_name_en : '')+" ")]}},{key:"deleted_by",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.deleted_by.employee ? row.deleted_by.employee.employee_name_en : row.deleted_by.user_name)+" ")]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),_c('Drawer',{attrs:{"title":_vm.$t('filter'),"closable":false,"value":_vm.visible,"width":"300px"},on:{"on-close":function () { return (_vm.visible = false); }}},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t('loanRepayment.actualpaymentDate')))]),_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"placement":"bottom-end","transfer":true,"format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeActualPaymentDate},model:{value:(_vm.actual_payment_date),callback:function ($$v) {_vm.actual_payment_date=$$v},expression:"actual_payment_date"}})],1),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t('summaryOfActiveAccount.currency')))]),_c('br'),_c('RadioGroup',{model:{value:(_vm.currency_id),callback:function ($$v) {_vm.currency_id=$$v},expression:"currency_id"}},_vm._l((_vm.currencies),function(c,index){return _c('Radio',{key:index,staticClass:"mt-1",attrs:{"label":c.currency_id,"border":"","size":"small"}},[_vm._v(" "+_vm._s(c.currency_code)+" ")])}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }